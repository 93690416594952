body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.quillformat {
  min-height: 100px;
}

article.blog-post img {

  width: 70%;
}

.g-5 {
  --bs-gutter-x: 0;
}

.myport {
  margin-top: 20px;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
}

.linkresume {
  color: white;
  text-decoration: none;
}

.linkresume:hover {
  color: white;
  text-decoration: none;
}

@media print {
  .myport {
    width: auto;
  }

  #pagenav {
    display: none;
  }

  #navfooter {
    display: none;
  }
}